<!--
 * @Author: Zengwei
 * @Date: 2022-09-05 10:55:19
 * @LastEditTime: 2022-09-13 18:56:19
 * @LastEditors: zx
 * @Description: 表单应用配置界面
 * @FilePath: /manage-views/views/application/myApplication/index.vue
-->

<template>
  <div class="form-designer" v-loading="loading">
    <div class="form-designer-meter">
      <div class="form-designer-meter-tabs">
        <div class="form-head-title">
          <h3 class="title" @click="close">
            <i class="el-icon-arrow-left"></i>
            <span>{{ saasCurrentMenu.name }}</span>
          </h3>
        </div>
        <el-button type="text" class="form-close-btn">
          <i class="el-icon-close" @click="close"></i>
        </el-button>
        <ul class="el-menu-demo el-menu--horizontal el-menu" style="width: 100%; display: flex; justify-content: center;">
          <li
            v-for="item in tabs" :key="item.id"
            class="el-menu-item"
            :class="{'is-active': item.id === activeName}"
            @click="handleClick(item.id)"
          >
            <svg aria-hidden="true" class="iconfont-svg size-24">
              <use :xlink:href="item.icon"></use>
            </svg>
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="form-designer-meter-body">
        <custom-form v-if="activeName === 'form' && tableInfo.id" :tableInfo="tableInfo"></custom-form>
        <custom-config v-if="activeName === 'flow' && tableInfo.id" :tableInfo="tableInfo"></custom-config>
        <custom-list v-if="activeName === 'list' && tableInfo.id" :tableInfo="tableInfo"></custom-list>
        <custom-word v-if="activeName === 'word' && tableInfo.id" :tableInfo="tableInfo"></custom-word>
        <custom-excel v-if="activeName === 'excel' && tableInfo.id" :tableInfo="tableInfo"></custom-excel>
      </div>
    </div>
  </div>
</template>
<script>
import { MessageBox } from "element-ui";
import customForm from "./components/customForm.vue";
import customConfig from "./components/customConfig.vue";
import customList from "./components/customList.vue";
import customWord from "./components/customWord";
import customExcel from "./components/customExcel";
import { dataInterface } from "@/apis/data/index";
import { mapState } from 'vuex';
export default {
  components: {
    customForm,
    customConfig,
    customList,
    customWord,
    customExcel,
  },
  props: {
    formUuid: {
      type: String,
      default: ''
    },
    appMenuId: {
      type: [String,Number],
      default: 0
    },
  },
  data() {
    return {
      loading: false,
      defaultName:'form',
      activeName: 'form',
      tableInfo: {},
      tabs: [
        { id: 'form', name: '设计表单', icon: '#icona-biaodanyinqinghover'},
        { id: 'flow', name: '流程配置', icon: '#icona-liuchengyinqinghover'},
        { id: 'list', name: '扩展配置', icon: '#iconanquanjiaoyu'},
        { id: 'word', name: 'Word模板', icon: '#icona-dayinhover'},
        { id: 'excel', name: 'Excel模板', icon: '#iconshujubiaogeyinqing'},
      ]
    };
  },
  computed: {
    ...mapState(['saasCurrentMenu'])
  },
  mounted() {
    this.getDataInfo()
  },
  methods: {
    getDataInfo(){
      let params = {
        __method_name__: "dataInfo",
        object_uuid: "object62fb5e636d27a",
        transcode: 0,
        data_id: this.appMenuId,
      };
      dataInterface(params).then(res=>{
        this.tableInfo = res.data.data
      })
    },
    close(){
      this.$emit('close')
    },
    handleClick(data) {
      if (['form','flow','list'].includes(this.activeName)){
        MessageBox.confirm('您是否已保存内容，未保存会丢失，是否继续切换?', '提示', {
          confirmButtonText: '确定切换',
          cancelButtonText: '去保存',
          type: 'warning'
        }).then(() => {
          this.activeName = data;
        }).catch(() => {});
      } else {
        this.activeName = data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.size-24{
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.form-designer {
  width: 100%;
  height: 100%;
  display: flex;
}
.form-designer-tree {
  width: 300px;
  border: 1px solid #f2f3f5;

  .title{
    padding: 10px 10px 0 15px;
  }
}
.form-designer-meter {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .form-head-title{
    position: absolute;
    left: 10px;
    top: 22px;
    z-index: 10;
    h3{
      span{
        font-weight: normal;
      }
    }
  }

  .form-close-btn{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    color: #000000;
    z-index: 10;
  }
  .form-designer-meter-tabs {
    display: flex;
    background-color: #fff;
    justify-content: center;
    position: relative;
    .button-class {
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .form-designer-meter-body {
    flex: 1;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: 10px;
    overflow-y: auto;
  }
}
</style>

<!--
 * @Author: Zengwei
 * @Date: 2022-09-05 10:55:19
 * @LastEditTime: 2022-09-05  10:52:52
 * @LastEditors: Zengwei
 * @Description: 表单应用配置界面
 * @FilePath: src/manage-views/views/application/editFormApp/components/customConfig.vue
-->

<template>
  <div v-loading="showList" class="flow">
    <flow-designer
      ref="flowDesigner"
      v-if="!switchVisible && flowData"
      :form-uuid="detailInfo.form_module_uuid"
      :flow-data="flowData"
      :user-data="userData"
      :job-list="jobList"
      :form-item="formInputItem"
      :sub-flow-list="subFlowData"
      :editable="true"
      @on-save="submitFlow"
      @on-form="openFormDesign">
    </flow-designer>
    <div v-if="switchVisible" class="switch-show">
      <div>
        <img src="@/assets/images/flow.jpg" alt="">
        <p v-if="isForm">流程用于实现数据填写后的审批需求</p>
        <p v-if="isForm">如无需启用流程，可跳过此步骤</p>
        <p v-if="!isForm">暂未创建表单，请新增表单后使用流程！</p>
        <el-button v-else type="primary" class="button-class" @click="openFlow">开启流程</el-button>
      </div>
    </div>
    <div v-if="!switchVisible && flowData" class="close-button">
      <el-button type="primary" class="button-class" @click="closeFlow">关闭流程</el-button>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      title="流程节点自定义审批内容"
      fullscreen
      append-to-body
      custom-class="flow-form-design"
    >
      <section class="domain-body">
        <FormDesigner @form-data="saveFormDesign"></FormDesigner>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import { Button } from "element-ui";
import { dataInterface } from "@/apis/data/index";
import { flowEngine } from "@/apis/data/workflow.js";
import { getFromValue,editFromValue } from "@/apis/data/customForm";
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";
import {FlowDesigner} from "bimcc-ui"
import {getDepartmentUser, getJobList} from "@/api/saasManage";
import FormDesigner from "@/custom-component/form/FormDesigner";

export default {
  components: {
    FormDesigner,
    'el-button': Button,
    'flow-designer': FlowDesigner,
  },
  data() {
    return {
      showTable:false,
      flowData: null,
      userData: null,
      jobList: null,
      showList: false,
      switchVisible: true,
      detailInfo: {},
      form_uuid: null,
      formDesignData:null,
      formInputItem: [],
      subFlowData: [],
      isForm: false,
      dialogVisible: false,
    };
  },
  props: {
    tableInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.getInfo()
  },
  methods: {
    openFormDesign(){
      this.dialogVisible = true;
    },
    getFlowDesign (param) {
      flowEngine.designShow(param).then(async (res) => {
        const userRes = await getDepartmentUser({})
        const jobRes = await getJobList({size: 999})
        const subFlowRes = await flowEngine.designList({parent: true})
        this.subFlowData = subFlowRes.data.data.data
        this.jobList = jobRes.data.data.data
        this.userData = userRes.data.data
        this.flowData = res.data.data
        this.switchVisible = false
        this.showList = false
      })
    },
    getInfo() {
      let params = {
        __method_name__: "dataInfo",
        object_uuid: "object62fb5e636d27a",
        transcode: 0,
        data_id: this.tableInfo.id,
      };
      this.showList = true
      dataInterface(params).then( async (res) => {
        this.detailInfo = res.data.data;
        let formUuid = ''
        if(this.detailInfo.form_module_uuid) {
          formUuid = this.detailInfo.form_module_uuid
        }
        // 没有拿到module_uuid  提示去新增表单  不进行下面的请求
        if(!formUuid) {
          this.showList = false
          return
        }else{
          this.isForm = true
        }
        getFromValue({},formUuid).then((res) => {
          this.formDesignData = res.data.data
          const { json } = res.data.data;
          const jsonData = JSON.parse(json);
          if (jsonData) {
            this.formInputItem = viewGetFormItem(jsonData.fields);
          }
          if(res.data.data.need_flow) {
            this.switchVisible = true
            this.getFlowDesign({ module: this.detailInfo.form_module_uuid })
          }else{
            this.showList = false
          }
        })
      });
    },
    // 打开流程
    openFlow() {
      this.showList = true
      editFromValue({...this.formDesignData,need_flow:1},this.detailInfo.form_module_uuid).then((res) => {
        if(res.data.code === 200) {
          this.switchVisible = true
          this.getFlowDesign({ module: this.detailInfo.form_module_uuid })
        }
      })
    },
    // 关闭流程
    closeFlow() {
      this.showList = true
      editFromValue({...this.formDesignData,need_flow:0},this.detailInfo.form_module_uuid).then((res) => {
        this.showList = false
        if(res.data.code === 200) {
          this.switchVisible = true
          this.flowData = null
        }
      })
    },
    submitFlow(data){
      flowEngine.designSave(this.flowData.id,data).then(res=>{
        if(res.data.code === 200) {
          this.$message.success('保存流程成功');
        }
      })
    },
    saveFormDesign(formDesignData){
      const design = JSON.parse(formDesignData.json)
      this.$refs.flowDesigner.setExtendForm(design)
      this.dialogVisible = false
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.flow-form-design){
  .domain-body{
    width: 100%;
    height: calc(100vh - 60px);
  }
  .el-dialog__header{
    border-bottom: 1px solid #f1e8e8;
  }
  .el-dialog__body{
    padding: 0;
  }
}

.flow {
  width: 100%;
  height: 100%;
  position: relative;
  .close-button {
    position: absolute;
    left: 20px;
    bottom: 0;
  }
  .switch-show {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      color: #999;
      font-size: 16px;
      text-align: center;
      margin: 5px 0;
    }
    .button-class {
      margin-top: 10px;
    }
  }
}
</style>

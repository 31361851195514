/*
 * @Author: your name
 * @Date: 2021-12-10 16:44:11
 * @LastEditTime: 2021-12-17 14:48:27
 * @LastEditors: luocheng
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\apis\data\customForm.js
 */
import { axios } from '../http/request';

export function getFromValue(params,id){
  params.transcode = 0
  return axios.request({
    url: '/api/form4/design/'+id,
    method: 'GET',
    params,
  })
}

export function submitFromValue(data){
  data.transcode = 0
  return axios.request({
    url: '/api/form4/design',
    method: 'POST',
    data,
  })
}
export function editFromValue(data,id){
  data.transcode = 0
  return axios.request({
    url: '/api/form4/design/' + id,
    method: 'PUT',
    data,
  })
}

export function setDesignConf(data,module){
  return axios.request({
    url: '/api/form4/setconfig/' + module,
    method: 'PUT',
    data,
  })
}

export function commonApi(data){
  return axios.request({
    url: '/api/mapi',
    method: 'post',
    data,
  })
}
export function getFieldPropApi(params){
  return axios.request({
    url: '/api/form4/getFieldProp',
    method: 'get',
    params,
  })
}
export function setFieldPropApi(data){
  return axios.request({
    url: '/api/form4/setFieldProp',
    method: 'post',
    data,
  })
}

/* 表单word模板配置 */
export const wordTemplateListApi = (params) => {
	return axios.request({
		url: 'api/form4/wordTemplate',
		params: params,
		method: 'get'
	})
};
export const wordTemplateAddApi = (params) => {
	return axios.request({
		url: 'api/form4/wordTemplate',
		data: params,
		method: 'post'
	})
};
export const wordTemplateDetailApi = (id) => {
	return axios.request({
		url: 'api/form4/wordTemplate/'+id,
		// params: params,
		method: 'get'
	})
};
export const wordTemplateEditApi = (data,id) => {
	return axios.request({
		url: 'api/form4/wordTemplate/'+id,
		data,
		method: 'put'
	})
};
export const wordTemplateDelApi = (id) => {
	return axios.request({
		url: 'api/form4/wordTemplate/'+id,
		// data,
		method: 'delete'
	})
};

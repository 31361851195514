<!--
    @name: index
    @description：index
    @author: ZengWei
    @date: 2021-12-16 17:05
-->
<template>
	<div class="form-data-list" v-loading="loading">
    <div class="container">
      <div class="search-container" v-if="searchColumnList && searchColumnList.length">
        <div class="search">
          <div class="search-comp">
            <el-form
              label-position="top"
              size="small"
              label-width="80px"
            >
              <el-row :gutter="20">
                <el-col
                  :span="6"
                  v-for="item in searchColumnList"
                  :key="item.field"
                >
                  <el-form-item :label="item.field_note">
                    <input-item :element="item.element"></input-item>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="search-btn">
            <el-button
              size="small"
              @click="searchRest"
            >重置</el-button>
            <el-button
              size="small"
              type="primary"
              @click="searchAction"
            >查询</el-button>
          </div>
        </div>
      </div>
      <div ref="tableWrapper" class="table-list">
        <el-table v-if="columnList && columnList.length" :data="tableData" border height="100%">
          <el-table-column align="center" type="selection" width="55"></el-table-column>
          <el-table-column
            v-for="(item,ind) in columnList"
            :key="item.key+ind"
            :label="item.title"
            :width="item.width">
            <template slot-scope="scope">
              <div v-if="judgeImg(scope.row[item.key])">
                <el-image
                  style="width: 60px; height: 60px"
                  :src="scope.row[item.key]"
                  fit="contain"
                  :preview-src-list="[scope.row[item.key]]"></el-image>
              </div>
              <div v-else-if="item.type === 'database'">
                <el-button type="text" @click="openDetailModal(scope.row)">查看关联数据</el-button>
              </div>
              <div v-else-if="item.type === 'form'">
                <el-button type="text" @click="openDetailModal(scope.row)">查看子表数据</el-button>
              </div>
              <div v-else-if="item.type === 'bim'">
                <el-button type="text">查看BIM</el-button>
              </div>
              <div v-else-if="item.type === 'gis'">
                <el-button type="text">查看GIS</el-button>
              </div>
              <div v-else-if="(''+scope.row[item.key]).length>15">
                <el-tooltip class="item" effect="dark" :content="scope.row[item.key]" placement="top">
                  <span>{{(''+scope.row[item.key]).substr(0,15) + '...'}}</span>
                </el-tooltip>
              </div>
              <div v-else>
                {{scope.row[item.key]}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="215" align="center">
            <template slot-scope="scope">
              <span
                v-if="btnItem(2,1)"
                class="operation-primary"
                @click="openDetailModal(scope.row)"
              >{{btnItem(2,1).name}}</span>
              <span
                v-if="btnItem(2,2)"
                class="operation-primary"
                @click="openEditModal(scope.row)"
              >{{btnItem(2,2).name}}</span>
              <span
                v-if="btnItem(2,4)"
                class="operation-primary"
                @click="exportWord(scope.row)"
              >{{btnItem(2,4).name}}</span>
              <el-dropdown v-if="detailSheetUuid && btnItem(2,5)" @command="handleExcelCommand($event, scope.row)">
                <span class="operation-primary el-dropdown-link">{{btnItem(2,5).name}}</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="info">预览</el-dropdown-item>
                  <el-dropdown-item command="export" divided>导出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span
                v-if="btnItem(2,3)"
                class="operation-del"
                @click="confirmDel(scope.row)"
              >{{btnItem(2,3).name}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div v-else class="empty">
          <el-empty :image-size="25" description="暂无列表配置！"></el-empty>
          <el-button type="primary" @click="goFormDesign">去设计表单</el-button>
        </div>
      </div>
    </div>

		<el-dialog
			:title="moduleName+ formModeTitle[formMode]"
      :fullscreen="isFull ? true : false"
			:visible.sync="dialogVisible"
      top="3%"
			width="1200px"
      append-to-body
			custom-class="global-dialog-overscroll-parser"
		>
      <!-- 自定义标题头 -->
      <template #title>
        <div class="custom-dialog-header">
          <div>
            {{ moduleName+ formModeTitle[formMode] }}
          </div>
          <div class="action-row">
            <i v-show="!isFull" class="iconfont icondaping-quanping action-item" @click.stop="isFull = !isFull"/>
            <i v-show="isFull" class="iconfont icondaping-feiquanping action-item" @click.stop="isFull = !isFull"/>
            <template v-if="needSider">
              <i v-show="sideShow" class="iconfont iconyouzhedie action-item" @click.stop="sideShow = !sideShow" />
              <i v-show="!sideShow" class="iconfont iconzuozhedie action-item" @click.stop="sideShow = !sideShow" />
            </template>
          </div>
        </div>
      </template>
      <template v-if="dialogVisible">
        <template v-if="['add','edit'].includes(formMode)">
          <Store
            ref="handleParser"
            v-if="dialogVisible && moduleUuid"
            :module-uuid="moduleUuid"
            :data-id="dataId"
            @on-submit="handleSubmit"
            @on-cancel="handleCancel"
            :formFillData="formFillData"
            :sideShow="sideShow"
            :lower-flow="lowerFlow"
            :arch-field="archField"
            @need-sider-change="handleNeedSiderChange"
          >
          </Store>
        </template>
        <template v-else>
          <Detail
            v-if="dialogVisible && moduleUuid"
            :data-id="dataId"
            :module-uuid="moduleUuid"
            :formFillData="formFillData"
            :sideShow="sideShow"
            @need-sider-change="handleNeedSiderChange"
          >
          </Detail>
        </template>
      </template>
      <div slot="footer" v-if="['add','edit'].includes(formMode)" style="text-align: right">
        <el-button type="default" @click="dialogVisible = false" style="margin-right: 10px;">取消</el-button>
        <el-button type="primary" @click="triggerSubmit">提交</el-button>
      </div>
		</el-dialog>

    <!-- 导出 -->
    <Spreadsheet
      v-if="showSheet && sheetFormData"
      :formData="sheetFormData"
      :id="sheetUuid"
      :isSave="false"
      :isHidden="true"
      @exportSuccess="onExportSuccess"
      :autoDownLoad="'excel'"
    ></Spreadsheet>

    <!-- 导出 & 预览 -->
    <el-dialog
      custom-class="form-bind-excel-dialog"
      title="预览"
      :visible.sync="showSheetEntry"
      fullscreen
    >
      <SheetEntry
        v-if="showSheetEntry"
        :loadType="'create'"
        :type="sheetAction"
        :excelUuid="sheetUuid"
        :excelFormData="sheetFormData"
        :excelMetaData="{}"
        :solveType="solveType"
      ></SheetEntry>
    </el-dialog>
    <SheetEntry
        v-if="sheetAction === 'export'"
        :type="'export'"
        :excelUuid="sheetUuid"
        :excelFormData="sheetFormData"
        :excelMetaData="{}"
        :exportType="'excel'"
        :solveType="solveType"
        @exportSuccess="sheetEntryExportSuccess"
      ></SheetEntry>
	</div>
</template>

<script>
/* eslint-disable */
import { formDesign } from '@/apis/data/workflow';
import InputItem from '@/custom-component/form/newParser/InputItem';
import Store from './components/Store';
import Detail from './components/Detail';
import {
  Button, Col, Form, FormItem,
  Pagination, Row, Table,
  TableColumn, Tooltip, Image,
  Dropdown, DropdownMenu, DropdownItem,
  Empty
} from 'element-ui';
import Spreadsheet from '@/custom-component/common/Spreadsheet';
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";
import SheetEntry from '@/custom-component/common/Entry';
import {downloadFile} from "@/utils/tools";
import {getToken} from "@/libs/util";

export default {
	name: 'index',
	components: {
		// ItemText,
		InputItem,
		Store,
		Detail,
    Spreadsheet,
		'el-image': Image,
		'el-tooltip': Tooltip,
		'el-pagination': Pagination,
    'el-table':Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-row': Row,
    'el-col': Col,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
		'el-empty': Empty,
    SheetEntry
	},
	props: {
		// 绑定的表单UUID
		moduleUuid: {
			type: String,
			required: true,
			default: ''
		},
		// 表单填充数据
		formFillData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			showSearch: false,
			tableData: [],

			loading: true,
			dialogVisible: false,
			formMode: 'add',
			formModeTitle: {
				add: '新增',
				edit: '修改',
				detail: '详情'
			},
			dataId: 0,
			moduleName: '表单列表数据',
			formDesignData: null,
      initFormDesign: null,
			searchColumn: [],
			searchColumnList: [],
			columnList: [],
			fieldsList: [],
			formValidate: {
        items:[]
      },
			page: 1,
			total: 0,
      // excel导出
      showSheet:false,
      sheetFormData:null,
      sheetUuid:'',
      // excel导出(ttheitao)
      listSheetConfig: null,
      detailSheetConfig: null,
      listSheetUuid: '',
      detailSheetUuid: '',
      sheetAction: '',
      showSheetEntry: false,
      solveType: "",
      // 是否需要侧边
      needSider: false,
      // 是否显示侧边
      sideShow: true,
      // 是否全屏
      isFull: false,

      lowerFlow: false,
      archField: '',

      // 功能区按钮与数据区按钮
      headBtnList: [
        // {action: 1, icon: 'icondaoru', name: '批量导入', show: true},
        // {action: 2, icon: 'icondaochu', name: '台账导出', show: true},
        {action: 3, icon: 'iconxinzeng3', name: '发起', show: true},
      ],
      dataBtnList: [
        {action: 1, name: '详情', show: true},
        {action: 2, name: '编辑', show: true},
        {action: 3, name: '删除', show: true},
        // {action: 4, name: '导出', show: true},
        // {action: 5, name: 'Excel', show: true},
      ],
		};
	},
  provide(){
    return {
      parser: this,
    }
  },
	created() {
		this.initFormData();
	},
	computed: {
    btnItem(){
      return (type,action)=>{
        let data = []
        if(type === 1){
          data = this.headBtnList.filter(i=>i.action === action)
        } else {
          data = this.dataBtnList.filter(i=>i.action === action)
        }
        if(data?.[0]){
          return data[0]
        }
        return false
      }
    },
    judgeImg(){
      return function (data){
        if(data){
          data += ''
          if(
            data.indexOf('.jpg') > -1  || data.indexOf('.png') > -1 ||
            data.indexOf('.gif') > -1 || data.indexOf('.jpeg') > -1
          ){
            return true
          }
        }
        return false
      }
    },
		columnConfig() {
			return function (key) {
				if (this.formValidate && this.formValidate.items) {
					const config = this.formValidate.items.filter(
						(item) => item.field === key
					);
					const configData = config[0] ? config[0].config : {};
					return configData;
				}
				return {};
			};
		},
	},
	methods: {
    handleNeedSiderChange(res) {
      this.needSider = res
    },
    handleBack() {
      this.$router.go(-1)
    },
		initFormData() {
			let param = { module_uuid: this.moduleUuid };
			formDesign.formDataList(param).then((res) => {
				if (res.data.code === 200) {
					const formDesign = res.data.data.design;
					let designData = JSON.parse(formDesign.json);
					if(designData){
						designData.need_flow = !!formDesign.need_flow;
						this.formDesignData = designData;
          } else {
						// this.$message.error('表单设计为空,请配置表单后使用');
          }
					this.moduleName = formDesign.module_name;
          this.lowerFlow = formDesign.element?.lowerFlow || false;
          this.archField = formDesign.element?.archField || '';
					this.columnList = res.data.data.columns;
					this.searchColumn = res.data.data.searchs;
					this.tableData = res.data.data.data;
          this.sheetUuid = res.data.data.sheet_uuid[0] || '';
          if(formDesign.element?.headBtn){
            this.headBtnList = formDesign.element.headBtn.filter(item=>item.show)
          }
          if(formDesign.element?.dataBtn){
            this.dataBtnList = formDesign.element.dataBtn.filter(item=>item.show)
          }
          this.listSheetConfig = res.data.data.list_sheet_uuid;
          this.detailSheetConfig = res.data.data.detail_sheet_uuid;
          this.listSheetUuid = this.listSheetConfig?.uuid || '';
          this.detailSheetUuid = this.detailSheetConfig?.uuid || '';

					this.page = res.data.data.current_page;
					this.total = res.data.data.total;

          if(designData?.fields){
            const searchList = Array.from(this.searchColumn, (item) => item.key);
            const reduceData = viewGetFormItem(designData.fields)
            this.searchColumnList = reduceData.filter(item => searchList.includes(item.field))
          }

					this.loading = false;
				}
			});
		},
		openAddModal() {
			this.dialogVisible = true;
			this.formMode = 'add';
			this.dataId = 0;
		},
		openEditModal(row) {
			this.dialogVisible = true;
			this.formMode = 'edit';
			this.dataId = row.id;
		},
		openDetailModal(row) {
			this.dialogVisible = true;
			this.formMode = 'detail';
			this.dataId = row.id;
		},
    exportExcel(row){
      if(!this.sheetUuid){
        this.$message.error('请配置导出模板');
        return false;
      }
      this.$loading({
        text: '导出中....'
      });
      formDesign.formDataExport(row.id).then((res) => {
        if (res.data.code === 200) {
          this.sheetFormData = {
            [this.moduleUuid]: res.data.data
          }
          this.showSheet = true;
        } else {
          this.$message.success(res.data.msg);
        }
      });
    },
    onExportSuccess(){
      this.$loading().close();
      this.showSheet = false;
      this.$message.success('导出成功！');
    },
    exportWord(row){
      this.loading = true
      const url = process.env.VUE_APP_BASE_URL + 'api/form4/exportword/'+row.id + '?token=' + this.$GetToken()
      downloadFile({url})
      this.loading = false
    },
		confirmDel(row) {
			this.$confirm('请确认是否要删除此条数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					formDesign.formDataDel(row.id).then((res) => {
						if (res.data.code === 200) {
							this.$message.success(res.data.msg);
							this.searchData();
						} else {
							this.$message.success(res.data.msg);
						}
						this.loading = false;
					});
				})
				.catch(() => {});
		},
		handleCancel() {
			this.dialogVisible = false;
		},
    triggerSubmit(){
      this.$refs.handleParser.triggerSubmit()
    },
		handleSubmit(formSubmitData, dataId) {
			formSubmitData.module_uuid = this.moduleUuid;
			if (dataId) {
				formDesign.formDataUpdate(formSubmitData, dataId).then((res) => {
					if (res.data.code === 200) {
						this.$message.success(res.data.msg);
						this.searchData();
						this.dialogVisible = false;
					} else {
						this.$message.success(res.data.msg);
					}
				});
			} else {
				formDesign.formDataStore(formSubmitData).then((res) => {
					if (res.data.code === 200) {
						this.$message.success(res.data.msg);
						this.searchData();
						this.dialogVisible = false;
					} else {
						this.$message.success(res.data.msg);
					}
				});
			}
		},
		changePage(page) {
      console.log(page)
			this.searchData({ ...page });
		},
		searchData(param = {}) {
			param.module_uuid = this.moduleUuid;
			formDesign.formDataList(param).then((res) => {
				if (res.data.code === 200) {
					this.tableData = res.data.data.data;

					this.page = res.data.data.current_page;
					this.total = res.data.data.total;
          this.$emit('getTotal', this.total)
				}
			});
		},
		searchRest() {
      const searches = this.searchColumnList;
      if (searches && searches instanceof Array) {
        for (let item of searches) {
          if (item.element.value instanceof Array) {
            item.element.value = []
          } else {
            item.element.value = ''
          }
        }
      }
			this.searchData();
		},
		searchAction() {
			const searches = this.searchColumnList;
			let formSubmitData = {};
			if (searches && searches instanceof Array) {
				for (let item of searches) {
					if (item.element.value) {
						formSubmitData[item.field] = item.element.value;
					}
				}
			}
			const keys = Object.keys(formSubmitData);
			if (keys.length > 0) {
				let params = { search: formSubmitData };
				this.searchData(params);
			}
			this.showSearch = false;
		},
    handleExcelCommand(command, row){
      this.$loading();
      this.sheetUuid = this.detailSheetUuid;
      this.solveType = this.detailSheetConfig.config.solveType;

      if(command == 'info'){
        this.sheetAction = 'info';

        formDesign.formDataDetailWithMonitor({
          module_uuid:this.moduleUuid,
          id:row.id
        }).then((res)=>{
          if(res.data.code === 200){
            this.sheetFormData = {
              [this.moduleUuid]: res.data.data
            }

            this.showSheetEntry = true;
          }

          this.$loading().close();
        })
        .catch((err)=>{
          this.sheetAction = "";
          this.$loading().close();
          console.log(err);
        });
      }else{
        formDesign.formDataDetailWithMonitor({
          module_uuid:this.moduleUuid,
          id:row.id
        }).then((res)=>{
          if(res.data.code === 200){
            this.sheetFormData = {
              [this.moduleUuid]: res.data.data
            }
            this.sheetAction = "export";
          }
        })
        .catch((err)=>{
          this.$loading().close();
          this.sheetAction = "";
          console.log(err);
        });
      }
    },
    handleListExport(){
      this.$loading();
      this.sheetUuid = this.listSheetUuid;
      this.solveType = this.listSheetConfig.config.solveType;

      formDesign.formDataListWithMonitor({
        module_uuid:this.moduleUuid,
        size: 10000,
      }).then((res)=>{
        if(res.data.code === 200){
          this.sheetFormData = {
            [this.moduleUuid]: res.data.data
          }

          this.sheetAction = "export";
        }
      })
      .catch((err)=>{
        this.sheetAction = "";
        this.$loading().close();
        console.log(err);
      });
    },
    sheetEntryExportSuccess(){
      this.$loading().close();
    },
    goFormDesign() {
      this.$emit('goFormDesign')
    }
	}
};
</script>

<style lang="less" scoped>
:deep(.el-table--border){
  border-bottom: 1px solid #EBEEF5;
}

:deep(.common-dialog) {
  .el-dialog__body{
    padding: 0;
  }
}

.form-data-list {
  height: 100%;
  flex: 1;
  overflow: hidden;

  .container{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .search-container{
    z-index: 10;
    width: 100%;
    background-color: #ffffff;
    box-shadow:-5px 5px 10px -4px #ededed,5px -5px 10px -4px #ededed;
    margin-bottom: 15px;

    .search {
      position: relative;
      margin-top: 10px;
      padding: 0 15px 10px;
      border-bottom: 1px solid #ebeef5;

      :deep(.el-select) {
        width: 100%;
      }

      .search-btn {
        position: absolute;
        bottom: 20px;
        right: 15px;
      }

      :deep(.search-comp) {
        padding-right: 170px;

        .el-form-item {
          margin-bottom: 10px !important;
        }

        .el-form-item__label {
          line-height: 26px;
          padding: 0;
        }
      }
    }
  }

	.table-list {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
		.operation-primary {
			padding: 0 5px;
			color: #0076ff;
			position: relative;
			cursor: pointer;
			font-size: 12px;
		}

		.operation-primary:not(:last-child):after {
			content: '';
			height: 12px;
			width: 1px;
			background-color: #c2c5cc;
			position: absolute;
			top: 3px;
			right: 0;
		}

		.operation-del {
			font-size: 12px;
      padding-left: 8px;
      color: red;
      cursor: pointer;
		}
	}

	.pagination {
    margin-top: 10px;
    margin-right: 5px;
    text-align: center;
	}
}
</style>

<style lang="less">
.form-bind-excel-dialog {
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    flex: 1;
  }
}
</style>

<style lang="less">
// 全屏下使其铺满屏幕
.global-dialog-overscroll-parser.is-fullscreen {
  height: 100%;
  max-height: 100%;
  // 全屏下使屏幕居于屏幕底部
  .message-container.message-input{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
.global-dialog-overscroll-parser{
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 6px 6px;
  box-sizing: border-box;
  padding: 0px;
  .el-dialog__header{
    border-bottom: 1px solid #f2f3f5;
    padding: 0;
    background: transparent;
    .custom-dialog-header{
      height: 48px;
      background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
      border-radius: 8px 8px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 68px 0 24px;
      color: #fff;
      .action-row{
        display: flex;
        .action-item{
          color: #fff;
          font-size: 20px;
          & + .action-item{
            margin-left: 24px;
          }
        }
      }
    }
    .el-dialog__headerbtn{
      top: 14px;
      right: 24px;
      .el-dialog__close{
        color: #fff;
          font-size: 20px;
      }
    }
  }
  .el-dialog__body{
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding: 0px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    .pcparser-warp{
      padding: 0;
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>

<!--
 * @Author: zx
 * @Date: 2022-08-25 11:43:07
 * @LastEditTime: 2022-11-03 14:57:20
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="my-application">
    <div v-loading="getListLoading" class="application-content">
      <div class="content-views">
        <div class="content-container">
          <!-- <div>
            <span>{{saasCurrentMenu.name}}</span>
          </div> -->
          <div class="content-views-tabs">
            <el-tabs
              v-model="currentTab"
              type="card"
              class="tabs"
              @tab-click="onTabs"
            >
              <el-tab-pane
                v-for="(item, index) in tabsList"
                :key="'tabs' + index"
                :label="item.name"
                :name="index + ''"
              >
                <span class="tab-pane-label" slot="label">
                  <i :class="'iconfont ' + item.icon"></i>
                  {{ item.name }}
                  <el-dropdown
                    v-if="index !== 0"
                    placement="bottom"
                  >
                    <span class="el-dropdown-link">
                      <i class="iconfont iconjijia_sanjiaoxiangxia"></i>
                    </span>
                    <el-dropdown-menu class="" slot="dropdown">
                      <el-dropdown-item>
                        <p @click="editTab(item)">编辑</p>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <p @click="deleteTab(item)">删除</p>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <p @click="cancelShowTab(item)">取消固定</p>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </el-tab-pane>
            </el-tabs>
            <div class="add-icon">
              <el-dropdown
                trigger="click"
                @command="addTypeClick"
              >
                <span class="el-dropdown-link">
                  <i class="iconfont iconkuaisuxinzeng"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="table">
                    <i class="iconfont iconsaidi-daohang-biaoge"></i>
                    表格视图
                  </el-dropdown-item>
                  <el-dropdown-item command="card">
                    <i class="iconfont iconxingxiang2"></i>
                    卡片视图
                  </el-dropdown-item>
                  <el-dropdown-item command="board">
                    <i class="iconfont icongongsi1"></i>
                    看板视图
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="created-new">
          <el-button v-if="showEdit()" type="default" size="mini" icon="el-icon-edit" @click="editForm()" round>设计表单</el-button>
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="openAddForm" round>发起</el-button>
        </div>
      </div>
      <div class="content-list">
        <FormDataList
          :key="key"
          ref="formDataList"
          v-show="currentTypeObj.tab.type === 'default'"
          :moduleUuid="formUuid"
          @getTotal="getTotal"
          @goFormDesign="editForm()"
        >
        </FormDataList>
        <TableList v-if="currentTypeObj.tab.type === 'table'" :currentTypeObj="currentTypeObj" />
        <CardList v-if="currentTypeObj.tab.type === 'card'" :currentTypeObj="currentTypeObj" />
        <BoardList v-if="currentTypeObj.tab.type === 'board'" :currentTypeObj="currentTypeObj" />
        <div class="pagination-container">
          <el-pagination
            class="pagination"
            @size-change="onSize"
            @current-change="onPage"
            :current-page="+pager.page"
            :page-sizes="[15, 20, 50, 100, 200]"
            :page-size="+pager.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="+pager.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增tabs -->
    <el-dialog
      :visible.sync="showAddTabs"
      append-to-body
      fullscreen
      lock-scroll
      custom-class="no-header-dialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="100%">
      <EditViewer
        v-if="showAddTabs"
        :formUuid="formUuid"
        :editData="editData"
        :editTabVisible="editTabVisible"
        :viewer-type="addType"
        :typeObj="editTypeObject"
        @close="closeTabDrawer">
      </EditViewer>
    </el-dialog>
    <!--表单应用配置-->
    <el-dialog
      :visible.sync="editFormAppDialog"
      append-to-body
      fullscreen
      lock-scroll
      custom-class="no-header-dialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="100%">
      <EditFormApp
        v-if="editFormAppDialog"
        :form-uuid="formUuid"
        :app-menu-id="appMenuId"
        @close="closeAppEditor">
      </EditFormApp>
    </el-dialog>
  </div>
</template>
<script>
import TableList from './components/tableList';
import CardList from "./components/cardList.vue";
import BoardList from "./components/boardList.vue";
import EditViewer from "@/manage-views/views/application/myApplication/EditViewer";
import EditFormApp from "@/manage-views/views/application/editFormApp";
import FormDataList from "@/manage-views/views/application/myApplication/formDataList";
import { Tabs, TabPane, Button, Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import { mapiRequest } from '@/apis/http/saasMapiRequest'
import { getFormList } from '@/api/application'
import { mapState } from 'vuex';
import {uniqid} from "@/plugins/util";
export default {
  components:{
    FormDataList,
    TableList,
    CardList,
    BoardList,
    EditViewer,
    EditFormApp,
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-button': Button,
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
  },
  props:{
  },
  data() {
    return {
      key: uniqid(),
      listData: [],
      tabsList: [
        {
          name: '全部数据',
          icon: 'iconxingxiang2',
          type: 'default'
        }
      ], // 视图列表
      currentTypeObj: {
        tab: {
          name: '全部数据',
          icon: 'iconxingxiang2',
          type: 'default'
        },
        list: []
      },
      currentTab: '0', // 当前选择的卡片视图
      pager: {
				page: 1,
				size: 15,
				total: 0
			},
      showAddTabs: false,
      editFormAppDialog: false,
      formUuid: this.$route.query.formUuid,
      appMenuId: this.$route.query.appMenuId,
      addType: '', // 新增tab类型
      activeMenu: 'base', // 新增tab menu显示
      /* 基础信息form表单对象 */
      baseData: {
        name: ''
      },
      /* 数据展示表单对象 */
      infoData: {
        name: ''
      },
      editData: {},
      editTabVisible: false,
      getListLoading: false, // 数据列表请求loading
      editTypeObject: {}, // 数据列表请求loading
    }
  },
  computed: {
    ...mapState(['saasCurrentMenu'])
  },
  watch: {
		$route: {
			handler(val) {
        this.key = uniqid()
        /* 清除原来tab选择  重置数据 */
        this.formUuid = val.query.formUuid
        this.appMenuId = val.query.appMenuId
        this.currentTab = '0'
        this.currentTypeObj = {
          tab: {
            name: '全部数据',
            icon: 'iconxingxiang2',
            type: 'default'
          },
          list: []
        }
        this.getTabList()
        if (this.$route.query?.add) {
          this.editForm()
        }
			},
			deep: true,
			immediate: true
		}
  },
  created() {
    this.getTabList()
    if (this.$route.query?.add) {
      this.editForm()
    }
  },
  methods:{
    closeAppEditor(){
      this.key = uniqid()
      this.editFormAppDialog = false
    },
    getTabList() {
      const data = {
				__method_name__: 'dataList',
				object_uuid: 'object63204356e9ed4',
				view_uuid: 'view632051f6a290f',
				transcode: 0,
        form_module_uuid: this.formUuid,
      }
      mapiRequest(data).then((res) => {
        if (res.status === 200 && res.data.code === 200) {
          this.tabsList = [
            {
              name: '全部数据',
              icon: 'iconxingxiang2',
              type: 'default'
            }
          ]
          let arr = res.data.data
          if (arr && arr.length) {
            arr = arr.filter((item) => +item.is_head === 1)
          }
          this.tabsList.push(...arr)
          this.tabsList.forEach(element => {
            if (element.view_show_type) {
              if (+element.view_show_type === 1) {
                element.type = 'table'
              } else if (+element.view_show_type === 2) {
                element.type = 'card'
              } else if (+element.view_show_type === 3) {
                element.type = 'board'
              }
            }
          });
          /* 清除原来tab选择  重置数据 */
          this.currentTab = '0'
          this.currentTypeObj = {
            tab: {
              name: '全部数据',
              icon: 'iconxingxiang2',
              type: 'default'
            },
            list: []
          }
          if (this.currentTypeObj.tab.type === 'default') {
            this.getRefInfo()
            return
          }
          this.getList()
        }
      })
    },
    openAddForm(){
      this.$refs.formDataList.openAddModal()
    },
    onTabs() {
      this.currentTypeObj.tab = this.tabsList[+this.currentTab]
      if (this.currentTypeObj.tab.type === 'default') {
        this.getRefInfo()
        return
      }
      this.getList()
    },
		/**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
			this.pager.size = size;
			this.pager.page = 1;
      if (this.currentTypeObj.tab.type === 'default') {
        this.getRefInfo()
        return
      }
			this.getList();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
			this.pager.page = page;
      if (this.currentTypeObj.tab.type === 'default') {
        this.getRefInfo()
        return
      }
			this.getList();
		},
    getTotal(total) {
      this.pager.total = total
    },
    /* 调用default类型请求数据 */
    getRefInfo() {
      this.$refs.formDataList.changePage({ page: this.pager.page, per_page: this.pager.size })
    },
    getList() {
      const params = {
        viewUuid: this.currentTypeObj.tab.id,// this.currentTypeObj.tab.id,
        page: this.pager.page,
        size: this.pager.size,
      }
      this.getListLoading = true
      this.currentTypeObj.list = []
      getFormList(params).then((res) => {
        this.getListLoading = false
        if (res.status === 200  && res.data.code === 200) {
          this.pager.total = res.data.data.total
          this.pager.page = res.data.data.current_page
          this.currentTypeObj.list = res.data.data.data
          this.currentTypeObj.fields = res.data.data.fields
        }
      })
    },
    addTypeClick(type) {
      this.addType = type
      this.editTabVisible = false
      this.showAddTabs = true
    },
    getTableType() {
      if(this.addType === 'table') {
        return '表格视图'
      } else if(this.addType === 'card') {
        return '卡片视图'
      } else if(this.addType === 'board') {
        return '看板视图'
      }
    },
    chooseMenu(str) {
      this.activeMenu = str
    },
    /* 编辑表单 */
    editForm() {
      this.formUuid = this.$route.query.formUuid
      this.editFormAppDialog = true
    },
    /* 复制表单 */
    copyForm() {
    },
    /* 编辑tab视图 */
    editTab(info) {
      const data = JSON.parse(JSON.stringify(info))
      this.editData = data
      this.addType = data.type
      this.editTypeObject.tab = data
      this.editTypeObject.list = []
      this.editTabVisible = true
      this.showAddTabs = true
    },
    /* 取消tab视图 */
    cancelShowTab(data) {
      this.$confirm('是否确定取消' + data.name + '显示？').then(() => {
        const params = {
          __method_name__: 'updateData',
          object_uuid: 'object63204356e9ed4',
          view_uuid: 'view632051f6a290f',
          data_id: data.id,
          is_head: 0
        }
        mapiRequest(params).then((res) => {
          if (res.status === 200 && res.data.code === 200) {
            this.$message.success('取消成功！')
            this.getTabList()
          }
        })
      })
      .catch(() => {});
    },
    /* 删除tab视图 */
    deleteTab(data) {
      this.$confirm('是否确定删除' + data.name + '？').then(() => {
        const params = {
          __method_name__: 'deleteData',
          object_uuid: 'object63204356e9ed4',
          data_id: data.id
        }
        mapiRequest(params).then((res) => {
          if (res.status === 200 && res.data.code === 200) {
            this.$message.success('删除成功！')
            this.getTabList()
          }
        })
      })
      .catch(() => {});
    },
    closeTabDrawer(boo) {
      this.showAddTabs = false
      if (boo) {
        this.getTabList()
      }
    },
    /* 判断编辑 */
    showEdit() {
      return this.$route.query.create_user_id && +this.$route.query.create_user_id === JSON.parse(localStorage.getItem('userInfo')).id
    }
  }
}
</script>
<style lang='less'>
.el-tabs--card > .el-tabs__header {
  border: none;
}

:deep(.el-tabs) {
  box-sizing: border-box;

  .el-tabs__header {
    border: none !important;
    margin: 0;
  }

  .el-tabs__content {
    display: none;
  }

  .el-tabs__nav {
    height: 42px;
    border: none;
  }

  .el-tabs__item {
    box-sizing: border-box;
    // margin: 0 5px;
    margin-top: 2px;
    height: 100%;
    box-sizing: border-box;
    line-height: 38px;
    font-family: PingFangSC, PingFangSC-Medium;
    border: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    margin-right: -18px;
    padding: 0 30px 0 30px;

    &:hover {
      padding: 0 30px 0 30px;
      // color: #515a6e;
      color: #409EFF;
      // background: #E9EBF0;
      background: #dee1e6;
      mask: url('~@/assets/images/layout/smoot-tab.png');
      mask-size: 100% 100%;

      .tab-pane-label {
        i {
          color: #409EFF;
        }
      }
    }

    &.is-active {
      padding: 0 30px 0 30px;
      color: #409EFF;
      background: #e8f4ff;
      outline: none;
      mask: url('~@/assets/images/layout/smoot-tab.png');
      mask-size: 100% 100%;
      font-weight: bold;

      .tab-pane-label {
        i {
          color: #409EFF;
        }
      }
    }
  }

  .tab-pane-label {
    display: inline-block;
    height: 36px;
    overflow: hidden;

    .iconfont {
      color: #303133;
    }

    i {
      color: #303133;
      line-height: 38px;
      margin-right: 5px;
    }

    span {
      line-height: 38px;
    }
  }

  .el-icon-close {
    margin-top: -22px;
  }

  .el-tabs__nav-prev {
    line-height: 42px;
  }

  .el-tabs__nav-next {
    line-height: 42px;
  }

  .el-tabs__new-tab {
    display: none;
  }
}
</style>
<style lang='less' scoped>
:deep(.no-header-dialog){
  .el-dialog__header{
    padding: 0;
  }

  .el-dialog__body{
    padding: 0;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .el-dialog__close{
    display: none;
  }
}
.my-application {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .application-tree {
    width: 250px;
    height: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-right: 1px solid #eee;
  }
  .application-content {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f2f4f5;
    overflow: hidden;
    .content-views {
      width: 100%;
      height: 54px;
      padding: 12px 0 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      .content-container {
        flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        p {
          font-size: 14px;
          font-weight: 600;
        }
        .content-views-tabs {
          height: 42px;
          // margin-left: 10px;
          display: flex;
          align-items: center;
          flex: 1;
          overflow: hidden;
          -moz-user-select:none; /* Firefox私有属性 */
          -webkit-user-select:none; /* WebKit内核私有属性 */
          -ms-user-select:none; /* IE私有属性(IE10及以后) */
          -khtml-user-select:none; /* KHTML内核私有属性 */
          -o-user-select:none; /* Opera私有属性 */
          user-select:none; /* CSS3属性 */
          .tabs {
            max-width: calc(100% - 40px);
          }
          .el-tabs--card > .el-tabs__header {
            border: none;
          }
          :deep(.el-tabs) {
            box-sizing: border-box;
            .el-tabs__header {
              border: none !important;
              margin: 0;
            }
            .el-tabs__content {
              display: none;
            }
            .el-tabs__nav {
              height: 42px;
              border: none;
            }
            .el-tabs__item {
              box-sizing: border-box;
              // margin: 0 5px;
              margin-top: 2px;
              height: 100%;
              box-sizing: border-box;
              line-height: 38px;
              font-family: PingFangSC, PingFangSC-Medium;
              border: none;
              transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
              margin-right: -18px;
              padding: 0 30px 0 30px;
              &:hover {
                padding: 0 30px 0 30px;
                // color: #515a6e;
                color: #409EFF;
                // background: #E9EBF0;
                background: #dee1e6                     ;
                mask: url('~@/assets/images/layout/smoot-tab.png');
                mask-size: 100% 100%;
                .tab-pane-label {
                  i {
                    color: #409EFF;
                  }
                }
              }
              &.is-active {
                padding: 0 30px 0 30px;
                color: #409EFF;
                background: #e8f4ff;
                outline: none;
                mask: url('~@/assets/images/layout/smoot-tab.png');
                mask-size: 100% 100%;
                font-weight: bold;
                .tab-pane-label {
                  i {
                    color: #409EFF;
                  }
                }
              }
            }
            .tab-pane-label {
              display: inline-block;
              height: 36px;
              overflow: hidden;
              .iconfont {
                color: #303133;
              }
              i {
                color: #303133;
                line-height: 38px;
                margin-right: 5px;
              }
              span {
                line-height: 38px;
              }
            }
            .el-icon-close {
              margin-top: -22px;
            }
            .el-tabs__nav-prev {
              line-height: 42px;
            }
            .el-tabs__nav-next {
              line-height: 42px;
            }
            .el-tabs__new-tab {
              display: none;
            }
          }
          .add-icon {
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            i {
              font-size: 30px;
            }
          }
        }
      }
      .created-new {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 7px;
        box-sizing: border-box;
      }
    }
    .content-list {
      flex: 1;
      margin-top: 10px;
      padding: 16px 16px 8px 16px;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .pagination-container {
        display: flex;
        justify-content: center;
        .pagination {
          padding-top: 8px;
        }
      }
    }
  }
}
/* 新增tabs */
.add-tabs {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  .add-tabs-views {
    flex: 1;
    height: 100%;
    padding-right: 16px;
    box-sizing: border-box;
  }
  .add-tabs-config {
    width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    p {
      font-weight: 600;
      font-size: 20px;
    }
    .config-content {
      width: 100%;
      flex: 1;
      display: flex;
      .config-menu {
        width: 150px;
        height: 100%;
        border-right: 1px solid #eee;
        box-sizing: border-box;
        padding: 16px 10px 0 0;
        .el-menu {
          border-right: none;
        }
      }
      .config-menu-content {
        flex: 1;
        height: 100%;
      }
    }
  }
}
</style>

<!--
 * @Author: Zengwei
 * @Date: 2022-09-05 10:55:19
 * @LastEditTime: 2022-09-05  10:52:52
 * @LastEditors: Zengwei
 * @Description: 表单应用配置界面
 * @FilePath: src/manage-views/views/application/editFormApp/components/customList.vue
-->

<template>
	<div class="quality-meter-custom-ist">
		<div v-if="!formVisible && !isAdd" class="quality-meter-custom-ist" v-loading="loading">
			<header>
				<p style="padding-bottom:20px;font-weight: 600;">{{tableInfo.name}}</p>
			</header>
			<el-divider></el-divider>
      <section class="common-box filter-panel" style="margin-top:30px;">
        <div class="title" style="padding: 8px 0">
          <p style="font-weight: 600;line-height: 30px">扩展配置</p>
          <p>
            <el-button size="mini" type="primary" @click="saveExtConf">保存</el-button>
          </p>
        </div>
        <el-divider style="margin: unset!important;"></el-divider>
        <div class="content">
          <el-form label-position="left" label-width="120px" style="width: 100%;padding-top: 15px">
            <el-row :gutter="30">
              <el-col :span="12">
                <el-form-item label="下级流程发起">
                  <el-switch v-model="otherConf.lowerFlow"></el-switch>
                </el-form-item>
                <el-form-item label="组织架构字段" v-if="otherConf.lowerFlow">
                  <el-select v-model="otherConf.archField" clearable>
                    <el-option
                      v-for="item in formInputItem"
                      :key="item.field"
                      :label="item.label"
                      :value="item.field">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="功能按钮:" label-width="75px">
                  <div class="btn-wrapper" v-for="item in otherConf.headBtn" :key="item.action">
                    <div class="gutter">
                      <el-form-item label="操作" label-width="40px">
                        <el-select v-model="item.action">
                          <el-option label="批量导入" :value="1"></el-option>
                          <el-option label="台账导出" :value="2"></el-option>
                          <el-option label="发起" :value="3"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="gutter">
                      <el-form-item label="文案" label-width="40px">
                        <el-input v-model="item.name"></el-input>
                      </el-form-item>
                    </div>
                    <div class="gutter">
                      <el-form-item label="启用" label-width="40px">
                        <el-switch v-model="item.show"></el-switch>
                      </el-form-item>
                    </div>
                  </div>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="数据按钮:" label-width="75px">
                  <div class="btn-wrapper" v-for="item in otherConf.dataBtn" :key="item.action">
                    <div class="gutter">
                      <el-form-item label="操作" label-width="40px">
                        <el-select v-model="item.action">
                          <el-option label="详情" :value="1"></el-option>
                          <el-option label="编辑" :value="2"></el-option>
                          <el-option label="删除" :value="3"></el-option>
                          <el-option label="文档导出" :value="4"></el-option>
                          <el-option label="表格导出" :value="5"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="gutter">
                      <el-form-item label="文案" label-width="40px">
                        <el-input v-model="item.name"></el-input>
                      </el-form-item>
                    </div>
                    <div class="gutter">
                      <el-form-item label="启用" label-width="40px">
                        <el-switch v-model="item.show"></el-switch>
                      </el-form-item>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </section>

			<section class="common-box filter-panel" style="margin-top:30px;">
				<div class="title">
						<p style="font-weight: 600;">查询面板</p>
						<i class="iconfont iconxitongshezhi" @click="dialogShowFun('is_search','设置查询条件','searchData')"></i>
				</div>
				<el-divider style="margin: unset!important;"></el-divider>
				<div class="content" v-if="searchData && searchData.length">
					<div v-for="(item,index) in searchData" :key="'search'+index" style="width:310px;margin:20px 35px 0 0;">
							<p style="margin-bottom:8px;">{{item.field_note}}</p>
							<el-input readonly placeholder="请输入"></el-input>
					</div>
				</div>
				<el-empty :image-size="25" description="暂无查询条件" v-else></el-empty>
			</section>

			<section class="common-box filter-panel" style="margin-top:20px;">
				<div class="title">
						<p style="font-weight: 600;">列表</p>
						<i class="iconfont iconxitongshezhi" @click="dialogShowFun('is_column','设置列表项','listData')"></i>
				</div>
				<el-divider style="margin: unset!important;"></el-divider>
				<div class="content" v-if="showListData && showListData.length">
					<el-table
						:data="showListData"
						@header-dragend="changeWidth"
						border
						style="width: 100%;margin-top:20px;">
						<el-table-column
              v-for="(item) in listData"
              resizable
              :key="item.field"
              :label="item.field"
              :min-width="item.column_width">
							<template slot="header">
								{{item.field_note}}
							</template>
							<template>-</template>
						</el-table-column>
					</el-table>
				</div>
				<el-empty :image-size="25" description="暂无列表项" v-else></el-empty>
			</section>

			<el-dialog
				:title="title"
				:visible.sync="dialogVisible"
				width="40%"
        append-to-body
				:before-close="handleClose">
				<div>
					<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" style="font-weight: 600;">表单字段</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group v-model="checkedProps" @change="handleCheckedPropsChange">
						<el-checkbox style="margin-bottom: 10px;" v-for="item in fieldProps" :label="item" :key="item.field">{{item.field_note}}</el-checkbox>
					</el-checkbox-group>

					<el-checkbox :indeterminate="isIndeterminate_s" v-model="checkAll_s" @change="handleCheckAllChange_s" style="font-weight: 600;margin-top: 20px;">系统字段</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group v-model="checkedProps_s" @change="handleCheckedPropsChange_s">
						<el-checkbox style="margin-bottom: 10px;" v-for="item in systemProps" :label="item" :key="item.field">{{item.field_note}}</el-checkbox>
					</el-checkbox-group>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="comfirm">确 定</el-button>
				</span>
			</el-dialog>
		</div>
		<div v-else class="quality-meter-custom-ist">
			<div class="switch-show">
				<div>
					<img src="@/assets/images/flow.jpg" alt="">
					<p>暂未创建表单，请新增表单后使用列表设计！</p>
					<el-button v-if="isAdd" type="primary" class="button-class" @click="addFormClick">创建表单</el-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import { RadioGroup,RadioButton } from "element-ui";
import { Divider,Checkbox,CheckboxGroup,Empty } from "element-ui";
import { commonApi,getFromValue,getFieldPropApi,setFieldPropApi } from "@/apis/data/customForm";
import { submitFromValue,setDesignConf } from "@/apis/data/customForm";
import { dataInterface } from "@/apis/data/index";
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";

export default {
	components:{
		// "el-radio-group": RadioGroup,
		// "el-radio-button": RadioButton,
		"el-divider": Divider,
		"el-checkbox": Checkbox,
		'el-checkbox-group': CheckboxGroup,
		'el-empty': Empty,
	},
	props: {
		tableInfo: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data(){
		return{
			id:this.tableInfo.id,
			module_uuid:'',
			dialogVisible:false,
			title:'',
			loading:true,
			/* 弹框参数 */
			checkAll: false,
			checkedProps: [],
			fieldProps:[],
			isIndeterminate: false,

			systemProps:[],
			checkAll_s:false,
			checkedProps_s:[],
			isIndeterminate_s:false,

			flag:'is_search',//列表，搜索，新增显示标识

			/* 三者已经设置显示的数据 */
			searchData:[],
			addData:[],
			listData:[],
			formVisible:false,

			isAdd: false,
			structureId:'',

      formInputItem: [],
      otherConf: {
        lowerFlow: false,
        archField: '',
        headBtn: [
          // {action: 1,icon: 'iconxinzeng3', name: '批量导入',show: true},
          // {action: 2,icon: 'iconxinzeng3', name: '台账导出',show: true},
          {action: 3, icon: 'iconxinzeng3', name: '发起',show: true},
        ],
        dataBtn: [
          {action: 1, name: '详情',show: true},
          {action: 2, name: '编辑',show: true},
          {action: 3, name: '删除',show: true},
          {action: 4, name: '导出',show: true},
          {action: 5, name: 'Excel',show: true},
        ],
      }
		}
	},
	computed:{
		showListData(){
			if(this.listData.length>1){
				return this.listData.slice(0,1)
			}else{
				return []
			}
		}
	},
	methods:{
    saveExtConf(){
      const data = {element: this.otherConf};
      setDesignConf(data,this.module_uuid).then(res=> {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
        }
      })
    },
		/* 拖动宽度变化 */
		changeWidth(newWidth, oldWidth, column){
			let label = column.label
			let newData = JSON.parse(JSON.stringify(this.fieldProps.concat(this.systemProps)))
			newData.forEach(item=>{
				if(item.field == label){
					item.column_width = newWidth
				}
			})
			let params = {
				module_uuid:this.module_uuid,
				data:newData
			}
			setFieldPropApi(params).then(res=>{
				if(res){
					// this.changeShow(newData)
					this.getModuleUuid()
				}
			})
		},
		/* 多选处理 */
		handleCheckAllChange(val) {
			this.checkedProps = val ? this.fieldProps : [];
			this.isIndeterminate = false;
		},
		handleCheckedPropsChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.fieldProps.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldProps.length;
		},
		handleCheckAllChange_s(val) {
			this.checkedProps_s = val ? this.systemProps : [];
			this.isIndeterminate_s = false;
		},
		handleCheckedPropsChange_s(value) {
			let checkedCount = value.length;
			this.checkAll_s = checkedCount === this.systemProps.length;
			this.isIndeterminate_s = checkedCount > 0 && checkedCount < this.fieldProps.length;
		},
		handleClose(){
			this.dialogVisible = false
		},
		comfirm(){
			// if(this.checkedProps && this.checkedProps.length || (this.checkedProps_s && this.checkedProps_s.length)){
				let fields = this.checkedProps.concat(this.checkedProps_s).map(item=>item.field)
				let newData = JSON.parse(JSON.stringify(this.fieldProps.concat(this.systemProps)))
				newData.forEach(item=>{
					if(fields.indexOf(item.field)!=-1){
						item[this.flag]=1
					}else{
						item[this.flag]=0
					}
				})

				let params = {
					module_uuid:this.module_uuid,
					data:newData
				}
				setFieldPropApi(params).then(res=>{
					this.dialogVisible = false
					if(res){
						// this.changeShow(newData)
						this.getModuleUuid()
					}
				})
			// }

		},
		/* 设置页面展示数据 */
		changeShow(data){
			this.searchData = data.filter(item=>item.is_search)
			this.listData = data.filter(item=>item.is_column)
			this.addData = data.filter(item=>item.is_show)
		},
		/* 设置弹框打开时的选中样式 */
		changeCheckProps(flag){
			this.checkedProps_s=[]
			this.checkedProps=[]
			let systemStr = ['created_at','user_name','updated_at','user_name','flow_engine_status']
			// let newArr = this.fieldProps.concat(this.systemProps)
			this[flag].forEach(item=>{
				if(systemStr.indexOf(item.field)!=-1){
					this.checkedProps_s.push(item)
				}else{
					this.checkedProps.push(item)
				}
			})

			this.checkAll_s = this.checkedProps_s.length === this.systemProps.length;
			this.isIndeterminate_s = this.checkedProps_s.length > 0 && this.checkedProps_s.length < this.systemProps.length;
			this.checkAll = this.checkedProps.length === this.fieldProps.length;
			this.isIndeterminate = this.checkedProps.length > 0 && this.checkedProps.length < this.fieldProps.length;
		},
		dialogShowFun(flag,title,dataFlag){
			this.changeCheckProps(dataFlag)
			this.flag = flag
			this.title = title
			this.dialogVisible = true
		},
		async getModuleUuid(){
			this.loading = true
			let form_uuid,module_uuid
			let params = {
				data_id: this.id,
				object_uuid: "object62fb5e636d27a",
				transcode: 0,
				__method_name__: "dataInfo",
			}
			await commonApi(params).then(async res=>{
				if(res){
					form_uuid = res.data.data.form_module_uuid
					if(!form_uuid) {
						this.formVisible = true
					}
				}
			})
			if (form_uuid) {
				await getFromValue({},form_uuid).then(res=>{
					if(res){
						module_uuid = res.data.data.module_uuid
						this.module_uuid = module_uuid
            const { json } = res.data.data;
            const jsonData = JSON.parse(json);
            if (jsonData) {
              this.formInputItem = viewGetFormItem(jsonData.fields);
            }
            if(res.data.data.element){
              this.otherConf = res.data.data.element
            }
					}
				})
				await getFieldPropApi({module_uuid}).then(res=>{
					if(res){
						let data = res.data.data
						this.fieldProps = []
						this.systemProps = []
						let systemStr = ['created_at','user_name','updated_at','user_name','flow_engine_status']
						data.forEach(item=>{
							if(systemStr.indexOf(item.field)!=-1){
								this.systemProps.push(item)
							}else{
								this.fieldProps.push(item)
							}
						})
						this.changeShow(data)
					}
				})
			}
			this.loading = false
		},
		addFormClick() {
      let formParams = {
        module_name: this.tableInfo.name
      }
			/* 新增表单 */
      submitFromValue(formParams).then((res) => {
        let params = {
          __method_name__: "updateData",
          object_uuid: "object626118627602e",
          data_id: this.tableInfo.id,
          form_design_id: res.data.data.id,
          module_uuid: res.data.data.module_uuid,
          sheet_uuid: this.detailInfo?.sheet_uuid
        };
				/* 原始库module_uuid数据绑定 */
        dataInterface(params).then(()=>{
					this.getModuleUuid()
				})
      });

		}
	},
	created(){
		// console.log(this.tableInfo,'tableInfo');
	},
	mounted(){
		this.getModuleUuid()
	}
}
</script>
<style lang="less">
.quality-meter-custom-ist{
	.el-divider--horizontal{
		margin: 0;
	}
	.el-checkbox__input.is-checked+.el-checkbox__label{
		color: #606266;
	}
}
</style>
<style lang="less" scoped>
.btn-wrapper{
  display: flex;

  .gutter {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

.quality-meter-custom-ist{
	height: 100%;
	width: ~'calc(100% - 40px)';
	padding: 20px;
  .switch-show {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      color: #999;
      font-size: 16px;
      text-align: center;
      margin: 5px 0;
    }
    .button-class {
      margin-top: 10px;
    }
  }
	.common-box{
		border-radius: 2px;
    border: 1px solid #dcdfe6;
		padding: 0 16px 16px 16px;
	}
	.filter-panel{
		.title{
			padding: 16px 0;
			display: flex;
			justify-content: space-between;
		}
		.content{
			display: flex;
			flex-wrap: wrap;
			// justify-content: space-around;
		}
	}
}
</style>
